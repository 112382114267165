.icon-left {
    float: left;
    padding: 12px 25px 60px 15px;
    color: #4ECABE;
}

.text {
    text-align: left;
    padding: 0 0 0 15px;
}

.text h4 {
    padding-bottom: 5px;
}