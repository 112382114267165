.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	
	color:#FFF;
	border-radius:50px;
	text-align:center;
    font-size:30px;
	box-shadow: 2px 2px 3px #999;
    z-index:100;
}

.my-float{
	margin-top:16px;
}

.App {
    text-align: center;
    padding: 40px;
}

.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #007bff;
    color: #fff;
    border-radius: 50%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 100;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.floating-button:hover {
    background-color: #0056b3;
}

.active-class{
    color: #2AAAE2 !important;
}