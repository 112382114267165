  .modal-login {
      /* width: 50%; 350px */
      width: 50%;
  }

  .modal-login .modal-content {
      padding: 20px;
      border-radius: 5px;
      border: none;
  }

  .modal-login .modal-header {
      border-bottom: none;
      position: relative;
      justify-content: center;
  }

  .modal-login h4 {
      text-align: center;
      font-size: 26px;
      margin: 30px 0 -15px;
  }

  .modal-login .form-control:focus {
      border-color: #70c5c0;
  }

  .modal-login .form-control,
  .modal-login .btn {
      min-height: 40px;
      border-radius: 3px;
  }

  .modal-login .close {
      position: absolute;
      top: -5px;
      right: -5px;
  }

  .modal-login .modal-footer {
      background: #ecf0f1;
      border-color: #dee4e7;
      text-align: center;
      justify-content: center;
      margin: 0 -20px -20px;
      border-radius: 5px;
      font-size: 13px;
  }

  .modal-login .modal-footer a {
      color: #e82426;
  }

  .modal-login .avatar {
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: -70px;
      width: 95px;
      height: 95px;

      z-index: 9;
      background: #fff;
      padding: 15px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }

  .modal-login .avatar img {
      width: 100%;
  }

  .modal-login.modal-dialog {
      margin-top: 80px;
  }

  .modal-login .btn {
      color: #fff;
      border-radius: 4px;
      background: #17a2b8;
      text-decoration: none;
      transition: all 0.4s;
      line-height: normal;
      border: none;
  }

  .modal-login .btn:hover,
  .modal-login .btn:focus {
      background: #45aba6;
      outline: none;
  }

  .trigger-btn {
      display: inline-block;
      margin: 100px auto;
  }
 