span {
    font-size: 15px;
}

.learnMore {
    text-decoration: none;
    color: #0062cc;
    border-bottom: 2px solid #0062cc;
}

.box {
    padding:2px 0px;
}

.box-part {
    background: #FFF;
    border-radius: 0;
    padding: 10px 10px;
    margin: 30px 0px;
}

.text {
    margin: 20px 0px;
}

.fa {
    color: #4183D7;
}