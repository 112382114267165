.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
}

.timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #eeeeee;
    left: 50%;
    margin-left: -1.5px;
}

.timeline>li {
    margin-bottom: 20px;
    position: relative;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li>.timeline-panel {
    width: 46%;
    float: left;
    border: 1px solid #d4d4d4;
    /*border-radius: 2px;*/
    /*padding: 20px;*/
    position: relative;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}

.timeline>li>.timeline-panel:before {
    position: absolute;
    top: 26px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-left: 15px solid #ccc;
    border-right: 0 solid #ccc;
    border-bottom: 15px solid transparent;
    content: " ";
}

.timeline>li>.timeline-panel:after {
    position: absolute;
    top: 27px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #fff;
    border-right: 0 solid #fff;
    border-bottom: 14px solid transparent;
    content: " ";
}

.timeline>li>.timeline-badge {
    color: #fff;
    width: 24px;
    height: 24px;
    line-height: 50px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 16px;
    left: 50%;
    margin-left: -12px;
    /*background-color: #999999;*/
    z-index: 100;
    /*
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  */
}

.timeline>li.timeline-inverted>.timeline-panel {
    float: right;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.timeline-badge>a {
    color: #C5C7C5 !important;
}

.timeline-badge a:hover {
    color: #FF531F !important;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}

.timeline-body>p,
.timeline-body>ul {
    padding: 20px;
    margin-bottom: 0;
}

.timeline-body>p+p {
    margin-top: 5px;
}

.timeline-footer {
    padding: 20px;
    background-color: #f4f4f4;
}

.timeline-footer>a {
    cursor: pointer;
    text-decoration: none;
}

.tooltip {

    position: absolute;
    z-index: 1020;
    display: block;
    visibility: visible;
    padding: 5px;
    font-size: 11px;
    opacity: 0;
    filter: alpha(opacity=0);

}



.tooltip.top {
    margin-top: -2px;
}

.tooltip.right {
    margin-left: 2px;
}

.tooltip.bottom {
    margin-top: 2px;
}

.tooltip.left {
    margin-left: -2px;
}

.tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 0;
    margin-left: 0;
    border-left: 0 solid transparent;
    border-right: 5px solid transparent;
    border-top: 0 solid #000;
}

.tooltip.left .tooltip-arrow {
    bottom: 0;
    left: 0;
    margin-left: 0;
    border-left: 0 solid transparent;
    border-right: 5px solid transparent;
    border-top: 0 solid #000;
}

.tooltip.bottom .tooltip-arrow {
    bottom: 0;
    left: 0;
    margin-left: 0;
    border-left: 0 solid transparent;
    border-right: 5px solid transparent;
    border-top: 0 solid #000;
}

.tooltip.right .tooltip-arrow {
    bottom: 0;
    left: 0;
    margin-left: 0;
    border-left: 0 solid transparent;
    border-right: 5px solid transparent;
    border-top: 0 solid #000;
}

.tooltip-inner {
    width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #FF531F;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
}

@media (max-width: 767px) {
    ul.timeline:before {
        left: 40px;
    }

    ul.timeline>li>.timeline-panel {
        width: calc(100% - 90px);
        width: -moz-calc(100% - 90px);
        width: -webkit-calc(100% - 90px);
    }

    ul.timeline>li>.timeline-badge {
        left: 15px;
        margin-left: 0;
        top: 16px;
    }

    ul.timeline>li>.timeline-panel {
        float: right;
    }

    ul.timeline>li>.timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
    }

    ul.timeline>li>.timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
    }
}