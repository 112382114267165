.blogShort {
    border-bottom: 1px solid #ddd;
}

.add {
    background: #333;
    padding: 10%;
    height: 300px;
}


.btn-blog {
    color: #ffffff;
    background-color: #000066;
    border-color: #000066;
    border-radius: 0;
    margin-bottom: 10px
}

.btn-blog:hover,
.btn-blog:focus,
.btn-blog:active,
.btn-blog.active,
.open .dropdown-toggle.btn-blog {
    color: white;
    background-color: #34ca78;
    border-color: #34ca78;
}



.margin10 {
    margin-bottom: 10px;
    margin-right: 10px;
}