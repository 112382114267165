
      .rtable {
          display: inline-block;
          vertical-align: top;
          max-width: 100%;
          overflow-x: auto;
          white-space: nowrap;
          border-collapse: collapse;
          border-spacing: 0;
      }

      .rtable,
      .rtable--flip tbody {
          -webkit-overflow-scrolling: touch;
          background: radial-gradient(left, ellipse, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 0 center, radial-gradient(right, ellipse, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 100% center;
          background-size: 10px 100%, 10px 100%;
          background-attachment: scroll, scroll;
          background-repeat: no-repeat;
      }

      .rtable td:first-child,
      .rtable--flip tbody tr:first-child {
          background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, white), to(rgba(255, 255, 255, 0)));
          background-image: linear-gradient(to right, white 50%, rgba(255, 255, 255, 0) 100%);
          background-repeat: no-repeat;
          background-size: 20px 100%;
      }

      .rtable td:last-child,
      .rtable--flip tbody tr:last-child {
          background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, white), to(rgba(255, 255, 255, 0)));
          background-image: linear-gradient(to left, white 50%, rgba(255, 255, 255, 0) 100%);
          background-repeat: no-repeat;
          background-position: 100% 0;
          background-size: 20px 100%;
      }

      .rtable th {
          font-size: 11px;
          text-align: left;
          text-transform: uppercase;
          background: #f2f0e6;
      }

      .rtable th,
      .rtable td {
          padding: 6px 12px;
          border: 1px solid #d9d7ce;
      }

      .rtable--flip {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          overflow: hidden;
          background: none;
      }

      .rtable--flip thead {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-negative: 0;
          flex-shrink: 0;
          min-width: -webkit-min-content;
          min-width: -moz-min-content;
          min-width: min-content;
      }

      .rtable--flip tbody {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          position: relative;
          overflow-x: auto;
          overflow-y: hidden;
      }

      .rtable--flip tr {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          min-width: -webkit-min-content;
          min-width: -moz-min-content;
          min-width: min-content;
          -ms-flex-negative: 0;
          flex-shrink: 0;
      }

      .rtable--flip td,
      .rtable--flip th {
          display: block;
      }

      .rtable--flip td {
          background-image: none !important;
          border-left: 0;
      }

      .rtable--flip th:not(:last-child),
      .rtable--flip td:not(:last-child) {
          border-bottom: 0;
      }

      code {
          background: #fffbcc;
          font-size: 12px;
      }